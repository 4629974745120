import React, { useEffect, useState } from 'react';
import {
  Box, Typography, Switch, TextField, Button, IconButton,
  FormControlLabel, FormGroup, FormLabel, Select, MenuItem, InputLabel,
  Container, Paper, Grid, Dialog, DialogActions, DialogContent, DialogTitle,
  FormControl, Snackbar, CircularProgress, Checkbox, Stack, Radio, RadioGroup
} from '@mui/material';
import { Info as InfoIcon } from '@mui/icons-material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import useAxiosPrivate from "../utils/useAxiosPrivate";
import { useAuth } from "../utils/AuthProvider";
import MuiAlert from '@mui/material/Alert';

export default function NotificationSettings() {

  const [notificationId, setNotificationId] = useState(null);
  const [userId, setUserId] = useState(null);
  const [pressureOn, setPressureOn] = useState(false);
  const [sprayOn, setSprayOn] = useState(false);
  const [hotWorksAlerts, setHotWorks] = useState(false);
  const [waterOn, setWaterOn] = useState(false);
  const [eButtonOn, setEButtonOn] = useState(false);
  const [eCareOn, setECareOn] = useState(false);
  const [weekdayValue, setWeekdayValue] = useState('off');
  const [weekdaysOn, setWeekdaysOn] = useState(false);
  const [weekdayScheduleOn, setWeekdayScheduleOn] = useState(false);
  const [weekendValue, setWeekendValue] = useState('off');
  const [weekendsOn, setWeekendsOn] = useState(false);
  const [weekendScheduleOn, setWeekendScheduleOn] = useState(false);
  const [weekdaysStartTime, setWeekDaysStartTime] = useState(null);
  const [weekdaysEndTime, setWeekDaysEndTime] = useState(null);
  const [weekendsStartTime, setWeekEndsStartTime] = useState(null);
  const [weekendsEndTime, setWeekEndsEndTime] = useState(null);
  const [weekdayNotificationMode, setWeekdayNotificationMode] = useState("accumulation");
  const [weekendNotificationMode, setWeekendNotificationMode] = useState("accumulation");
  const [name, setName] = useState("");
  const [cellPhone, setCellPhone] = useState("");
  const [email, setEmail] = useState("");
  const [preferredLanguage, setPreferredLanguage] = useState("1");
  const [modalVisible, setModalVisible] = useState(false);
  const [infoModalVisible, setInfoModalVisible] = useState(false);
  const [smsOn, setSMSOn] = useState(false);
  const [pushNotificationsOn, setPushNotificationsOn] = useState(false);
  const [emailsOn, setEmailsOn] = useState(false);
  const [rCode, setRCode] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarAddOpen, setSnackbarAddOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const { cookies } = useAuth();
  const axiosPrivate = useAxiosPrivate();

  const widthStyle = { width: "fit-content" };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
    setSnackbarAddOpen(false);
  };

  const fetchData = () => {
    const controller = new AbortController();

    const getSettings = async () => {
      try {
        const response = await axiosPrivate.get('/api/User/settings/' + cookies.user, {
          signal: controller.signal
        });

        loadData(response.data);

      } catch (err) {
        console.log(err);
      }
    }

    getSettings();

    return () => {
      controller.abort();
    }
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line 
  }, []);

  const loadData = (settings) => {
    setNotificationId(settings.notificationId);
    setUserId(settings.userId);
    setPressureOn(settings.pressureOn);
    setSprayOn(settings.sprayOn);
    setHotWorks(settings.hotWorksAlerts);
    setWaterOn(settings.waterOn);
    setEButtonOn(settings.eButtonOn);
    setECareOn(settings.eCare);
    setSMSOn(settings.sms);
    setPushNotificationsOn(settings.pushNotification);
    setEmailsOn(settings.emailNotification);
    setWeekdayNotificationMode(settings.weekdayNotificationMode ?? "accumulation");
    setWeekendNotificationMode(settings.weekendNotificationMode ?? "accumulation");
    setName(settings.fullName);
    setCellPhone(settings.countryCode + " " + settings.cellPhone);
    setEmail(settings.email);
    setPreferredLanguage(settings.prefferedLanguage);
    setWeekdayValue(handleLoadChange(settings.weekdays, settings.weekdaySchedule, settings.weekdayNotificationMode ?? "accumulation"));
    setWeekendValue(handleLoadChange(settings.weekends, settings.weekendSchedule, settings.weekendNotificationMode ?? "accumulation"));
    setWeekdaysOn(settings.weekdays);
    setWeekendsOn(settings.weekends);
    setWeekdayScheduleOn(settings.weekdaySchedule);
    setWeekendScheduleOn(settings.weekendSchedule);
    setWeekDaysStartTime(dayjs(`2023-01-01T${settings.weekdayStartTime}`));
    setWeekDaysEndTime(dayjs(`2023-01-01T${settings.weekdayEndTime}`));
    setWeekEndsStartTime(dayjs(`2023-01-01T${settings.weekendStartTime}`));
    setWeekEndsEndTime(dayjs(`2023-01-01T${settings.weekendEndTime}`));
  }

  const handleSave = () => {

    setLoading(true);

    const notifSettings = {
      notificationId: notificationId,
      userId: userId,
      pressureOn: pressureOn,
      sprayOn: sprayOn,
      hotWorksAlerts: hotWorksAlerts,
      waterOn: waterOn,
      eButton: eButtonOn,
      eCare: eCareOn,
      sms: smsOn,
      pushNotification: pushNotificationsOn,
      emailNotification: emailsOn,
      weekdayNotificationMode: weekdayNotificationMode,
      weekendNotificationMode: weekendNotificationMode,
      fullName: name,
      cellPhone: cellPhone.substring(cellPhone.indexOf(' ') + 1),
      email: email,
      countryCode: cellPhone.substring(0, cellPhone.indexOf(' ')),
      prefferedLanguage: preferredLanguage,
      weekdays: weekdaysOn,
      weekEnds: weekendsOn,
      weekdaySchedule: weekdayScheduleOn,
      weekEndSchedule: weekendScheduleOn,
      weekdayStartTime: weekdaysStartTime && dayjs(weekdaysStartTime).isValid() ? new Date(weekdaysStartTime).toLocaleTimeString('en-US', { hour12: false }) : null,
      weekdayEndTime: weekdaysEndTime && dayjs(weekdaysEndTime).isValid() ? new Date(weekdaysEndTime).toLocaleTimeString('en-US', { hour12: false }) : null,
      weekendStartTime: weekendsStartTime && dayjs(weekendsStartTime).isValid() ? new Date(weekendsStartTime).toLocaleTimeString('en-US', { hour12: false }) : null,
      weekendEndTime: weekendsEndTime && dayjs(weekendsEndTime).isValid() ? new Date(weekendsEndTime).toLocaleTimeString('en-US', { hour12: false }) : null
    };

    axiosPrivate.post("/api/NotificationSettings/saveNotificationSettings", notifSettings)
      .then(() => {
        fetchData();
        setSnackbarOpen(true);
      })
      .catch((error) => {
        console.error(error);
        alert("Error saving settings. Please try again.");
      })
      .finally(() => {
        setLoading(false);
      })
  };

  const handleAddCode = () => {
    if (rCode !== "" || rCode !== null) {
      const registerCode = {
        userId: userId,
        registerCode: rCode
      }

      axiosPrivate.post("/api/NotificationSettings/addRCode", registerCode)
        .then(() => {
          setSnackbarAddOpen(true);
        })
        .catch((error) => {
          console.error(error);
          alert("Error add code. Please try again.");
        })
    }
  };

  const handleSms = (sms) => {
    if (sms && !smsOn) {
      alert('SMS Enabled: Message & Data rates may apply.');
    }
    setSMSOn(!smsOn);
  }

  const handlePreAction = () => {
    setPressureOn(!pressureOn);
    setWaterOn(!waterOn);
  };

  const handleWeekdays = (event) => {
    if (!event.target.checked) {
      setWeekdayScheduleOn(false);
    }

    setWeekdaysOn(!weekdaysOn);
  }

  const handleWeekends = (event) => {
    if (!event.target.checked) {
      setWeekendScheduleOn(false);
    }

    setWeekendsOn(!weekendsOn);
  }

  const languages = [
    { label: 'English', value: 'en' },
    { label: 'French', value: 'fr' },
    { label: 'Spanish', value: 'es' }
  ];

  const notificationModes = [
    { label: 'Accumulation', value: 'accumulation' },
    { label: 'Start/Stop', value: 'startStop' }
  ];

  const handleWeekdayChange = (event) => {
    let selected = event.target.value;
    setWeekdayValue(selected);
    if (selected === 'on24/7') {
      setWeekdaysOn(true);
      setWeekdayScheduleOn(false);
    } else if (selected === 'off') {
      setWeekdaysOn(false);
      setWeekdayScheduleOn(false);
    } else if (selected === 'accumulation') {
      setWeekdaysOn(true);
      setWeekdayScheduleOn(true);
      setWeekdayNotificationMode('accumulation');
    } else if (selected === 'startStop') {
      setWeekdaysOn(true);
      setWeekdayScheduleOn(true);
      setWeekdayNotificationMode('startStop');
    }
  };

  const handleWeekendChange = (event) => {
    let selected = event.target.value;
    setWeekendValue(selected);
    if (selected === 'on24/7') {
      setWeekendsOn(true);
      setWeekendScheduleOn(false);
    } else if (selected === 'off') {
      setWeekendsOn(false);
      setWeekendScheduleOn(false);
    } else if (selected === 'accumulation') {
      setWeekendsOn(true);
      setWeekendScheduleOn(true);
      setWeekendNotificationMode('accumulation');
    } else if (selected === 'startStop') {
      setWeekendsOn(true);
      setWeekendScheduleOn(true);
      setWeekendNotificationMode('startStop');
    }
  };

  const handleLoadChange = (days, schedule, mode) => {
    if (days && !schedule) {
      return 'on24/7'
    } else if (days && schedule && mode === 'accumulation') {
      return 'accumulation'
    } else if (days && schedule && mode === 'startStop') {
      return 'startStop'
    } else {
      return 'off'
    }
  }

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Container style={{ paddingTop: "40px" }}>
          <Typography variant="h4" style={{ paddingBottom: "20px" }}>Notification Settings</Typography>
          <Paper elevation={3} sx={{ padding: 3, marginBottom: 3 }}>
            <Typography variant="h6" paddingLeft={3} gutterBottom>Alerts I Want to Receive</Typography>
            <FormGroup>
              <FormControlLabel
                control={<Switch checked={pressureOn && waterOn} onChange={handlePreAction} />}
                label="Verified Fire - System Prepared for Engagement"
                style={widthStyle}
              />
              <FormControlLabel
                control={<Switch checked={sprayOn} onChange={() => setSprayOn(!sprayOn)} />}
                label="Suppression Activated"
                style={widthStyle}
              />
              <FormControlLabel
                control={<Switch checked={hotWorksAlerts} onChange={() => setHotWorks(!hotWorksAlerts)} />}
                label="Mobile Suppression Unit - Mode Changes"
                style={widthStyle}
              />
            </FormGroup>
          </Paper>

          <Paper elevation={3} sx={{ padding: 3, marginBottom: 3 }}>
            <Typography variant="h6" paddingLeft={3} gutterBottom>Alert Delivery Methods</Typography>
            <FormGroup>
              <FormControlLabel
                control={<Switch checked={smsOn} onChange={handleSms} />}
                label="SMS"
                style={widthStyle}
              />
              <FormControlLabel
                control={<Switch checked={pushNotificationsOn} onChange={() => setPushNotificationsOn(!pushNotificationsOn)} />}
                label="Fire Rover Mobile App Notifications"
                style={widthStyle}
              />
              <FormControlLabel
                control={<Switch checked={emailsOn} onChange={() => setEmailsOn(!emailsOn)} />}
                label="Email Notifications"
                style={widthStyle}
              />
            </FormGroup>
          </Paper>

          <Paper elevation={3} sx={{ padding: 3, marginBottom: 3 }}>
            <Stack direction="row">
              <Typography variant="h6" paddingLeft={3} gutterBottom>When I Want to Receive Alerts</Typography>
              <IconButton onClick={() => setInfoModalVisible(true)} sx={{ padding: "0px 4px 0px 4px", marginBottom: "8px", marginLeft: "4px" }}>
                <InfoIcon />
              </IconButton>
            </Stack>

            <Stack padding={2}>
              <FormControl>
                <FormLabel id="weekdays-controlled-radio-buttons-group">Weekdays</FormLabel>
                <RadioGroup
                  aria-labelledby="weekdays-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={weekdayValue}
                  onChange={handleWeekdayChange}
                  >
                  <FormControlLabel value="on24/7" control={<Radio />} label="On 24 Hours" />
                  <FormControlLabel value="off" control={<Radio />} label="Off" />
                  <FormControlLabel value="accumulation" control={<Radio />} label="On per Schedule: Accumulation — Notifications received during off hours will be held and delivered in bulk at the start of the next operational period." />
                  <FormControlLabel value="startStop" control={<Radio />} label="On per Schedule: Start/Stop — Notifications received during off hours will not be delivered but will be stored in archive." />
                </RadioGroup>
              </FormControl>
            </Stack>
            <Grid container direction="column" spacing={1}>
              {weekdaysOn && weekdayScheduleOn && (
                <>
                  <Grid item container spacing={2} alignItems="center" sx={{ paddingTop: "10px", paddingBottom: "5px" }}>
                    <Grid item>
                      <Typography>Start Time:</Typography>
                    </Grid>
                    <Grid item>
                      <TimePicker label="Start Time" value={weekdaysStartTime} onChange={setWeekDaysStartTime} />
                    </Grid>
                  </Grid>

                  <Grid item container spacing={2} alignItems="center">
                    <Grid item>
                      <Typography>End Time:</Typography>
                    </Grid>
                    <Grid item style={{ paddingLeft: "21px", paddingBottom: "10px" }}>
                      <TimePicker label="End Time" value={weekdaysEndTime} onChange={setWeekDaysEndTime} />
                    </Grid>
                  </Grid>
                </>
              )}

              <Stack paddingLeft={3}>  
                <FormControl>
                  <FormLabel id="weekends-controlled-radio-buttons-group">Weekends</FormLabel>
                  <RadioGroup
                    aria-labelledby="weekends-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={weekendValue}
                    onChange={handleWeekendChange}
                    >
                    <FormControlLabel value="on24/7" control={<Radio />} label="On 24 Hours" />
                    <FormControlLabel value="off" control={<Radio />} label="Off" />
                    <FormControlLabel value="accumulation" control={<Radio />} label="On per Schedule: Accumulation — Notifications received during off hours will be held and delivered in bulk at the start of the next operational period." />
                    <FormControlLabel value="startStop" control={<Radio />} label="On per Schedule: Start/Stop — Notifications received during off hours will not be delivered but will be stored in archive." />
                  </RadioGroup>
                </FormControl>
              </Stack>
              {weekendsOn && weekendScheduleOn &&(
                <>
                  <Grid item container spacing={2} alignItems="center" sx={{ paddingTop: "10px", paddingBottom: "5px" }}>
                    <Grid item>
                      <Typography>Start Time:</Typography>
                    </Grid>
                    <Grid item>
                      <TimePicker label="Start Time" value={weekendsStartTime} onChange={setWeekEndsStartTime} />
                    </Grid>
                  </Grid>

                  <Grid item container spacing={2} alignItems="center">
                    <Grid item>
                      <Typography>End Time:</Typography>
                    </Grid>
                    <Grid item style={{ paddingLeft: "21px", paddingBottom: "10px" }}>
                      <TimePicker label="End Time" value={weekendsEndTime} onChange={setWeekEndsEndTime} />
                    </Grid>
                  </Grid>
                </>
              )}

              <Dialog open={infoModalVisible} onClose={() => setInfoModalVisible(false)}>
                <DialogTitle>Time of Day Control Info</DialogTitle>
                <DialogContent>
                  <Typography>
                    Settings for weekdays and weekends are independent and can be the same or different for both.<br /><br />

                    <strong>Off</strong>: You will not receive notifications at any time regardless of Alert and Notification Settings.<br /><br />
                    <strong>On 24/7</strong>: You will receive notifications in real time 24/7.<br /><br />
                    <strong>On Per Schedule: Accumulation</strong>: Notifications received during off hours will be held and delivered in bulk at the start of the next operational period.<br /><br />
                    <strong>On Per Schedule: Start/Stop</strong>: Notifications received during off hours will not be delivered but will be stored in archive.
                  </Typography>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setInfoModalVisible(false)}>Close</Button>
                </DialogActions>
              </Dialog>

              <Dialog open={modalVisible} onClose={() => setModalVisible(false)}>
                <DialogTitle>Notification Modes Info</DialogTitle>
                <DialogContent>
                  <Typography>
                    Available when Time of Day Controls is turned on. <br /><br />
                    <strong>Accumulation</strong>: Notifications received during off hours will be held and delivered in bulk at the start of the next operational period. <br /><br />
                    <strong>Start/Stop</strong>: Notifications received during off hours will not be delivered but will be stored in archive.
                  </Typography>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setModalVisible(false)}>Close</Button>
                </DialogActions>
              </Dialog>
            </Grid>
          </Paper>

          <Paper elevation={3} sx={{ padding: 3, mb: 3 }}>
            <Typography variant="h6" paddingLeft={3} gutterBottom>Preferred Language</Typography>
            <FormControl fullWidth>
              <InputLabel>Select Preferred Language</InputLabel>
              <Select
                value={preferredLanguage}
                onChange={(e) => setPreferredLanguage(e.target.value)}
                label="Select Preferred Language"
              >
                {languages.map((lang) => (
                  <MenuItem key={lang.value} value={lang.value}>{lang.label}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Paper>

          <Paper elevation={3} sx={{ padding: 3, mb: 3 }}>
            <Typography variant="h6" paddingLeft={3} gutterBottom>Add Registration Codes</Typography>
            <FormGroup row>
              <TextField
                label="Register Code"
                value={rCode}
                onChange={(e) => setRCode(e.target.value)}
                sx={{ width: "20%", marginBottom: 2 }}
                variant='outlined'
              />
              <Button variant="contained" sx={{ marginBottom: 2 }} disableElevation onClick={handleAddCode}>
                Add Code
              </Button>
            </FormGroup>

          </Paper>

          <Grid item xs={12} style={{ paddingTop: "10px", paddingBottom: "10px" }}>
            <Box display="flex" justifyContent="flex-end">
              <Button variant="contained" onClick={handleSave}>
                {loading
                  ? <CircularProgress />
                  : "Save"}
              </Button>
            </Box>
          </Grid>
        </Container>
      </LocalizationProvider>
      <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={handleSnackbarClose}>
        <MuiAlert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
          Notification settings saved!
        </MuiAlert>
      </Snackbar>
      <Snackbar open={snackbarAddOpen} autoHideDuration={3000} onClose={handleSnackbarClose}>
        <MuiAlert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
          Registration Code added successfully!
        </MuiAlert>
      </Snackbar>
    </>
  );
}
