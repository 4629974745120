import React, { useEffect, useState } from 'react';
import {
  Box, Typography, TextField, Button, IconButton, InputAdornment, MenuItem,
  Container, Paper, Grid, CircularProgress, FormControl, InputLabel, Select
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import useAxiosPrivate from "../utils/useAxiosPrivate";
import { useAuth } from "../utils/AuthProvider";
import { MuiTelInput, matchIsValidTel } from 'mui-tel-input';
import axios from '../utils/Axios';

const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;

export function Account() {

  const [notificationId, setNotificationId] = useState(null);
  const [name, setName] = useState("");
  const [cellPhone, setCellPhone] = useState("");
  const [email, setEmail] = useState("");
  const [timeZone, setTimeZone] = useState("Eastern Standard Time");
  const [loading, setLoading] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [pwd, setPwd] = useState('');
  const [validPwd, setValidPwd] = useState(false);
  const [pwdFocus, setPwdFocus] = useState(false);

  const [matchPwd, setMatchPwd] = useState('');
  const [validMatch, setValidMatch] = useState(false);
  const [matchFocus, setMatchFocus] = useState(false);

  const [oldPwd, setOldPwd] = useState('');
  const [validOldPwd, setValidOldPwd] = useState(false);

  const { cookies } = useAuth();
  const axiosPrivate = useAxiosPrivate();

  const timeZones = [
    { label: "Eastern Standard", value: "Eastern Standard Time" },
    { label: "Central Standard", value: "Central Standard Time" },
    { label: "Mountain Standard", value: "Mountain Standard Time" },
    { label: "Pacific Standard", value: "Pacific Standard Time" },
    { label: "Western Indonesian", value: "Western Indonesian Time" },
    { label: "Eastern European", value: "Eastern European Time" },
    { label: "South Africa Standard", value: "South Africa Standard Time" },
    { label: "Central European", value: "Central European Time" },
    { label: "GMT Standard", value: "GMT Standard Time" },
    { label: "Brasilia Standard", value: "Brasilia Standard Time" },
    { label: "Colombia Standard", value: "Colombia Standard Time" },
    { label: "Australian Eastern Standard", value: "Australian Eastern Standard Time" },
    { label: "Philippine Standard", value: "Philippine Standard Time" },
    { label: "New Zealand Standard", value: "New Zealand Standard Time" },
    { label: "Singapore Standard", value: "Singapore Standard Time" },
    { label: "Indochina", value: "Indochina Time" },
    { label: "Tokyo Standard", value: "Tokyo Standard Time" },
    { label: "Korea Standard", value: "Korea Standard Time" },
    { label: "China Standard", value: "China Standard Time" },
    { label: "Turkey Standard", value: "Turkey Standard Time" },
    { label: "India Standard", value: "India Standard Time" },
    { label: "Pakistan Standard", value: "Pakistan Standard Time" },
    { label: "Afghanistan", value: "Afghanistan Time" },
    { label: "Sri Lanka Standard", value: "Sri Lanka Standard Time" },
    { label: "Myanmar", value: "Myanmar Time" },
    { label: "Iran Standard", value: "Iran Standard Time" },
    { label: "Western European", value: "Western European Time" },
    { label: "Greenwich Mean", value: "Greenwich Mean Time" },
    { label: "West Africa", value: "West Africa Time" },
    { label: "Mauritius Standard", value: "Mauritius Standard Time" },
    { label: "Cape Verde Standard", value: "Cape Verde Standard Time" },
    { label: "Seychelles", value: "Seychelles Time" },
    { label: "Central Africa", value: "Central Africa Time" },
    { label: "East Africa", value: "East Africa Time" },
    { label: "Réunion", value: "Réunion Time" },
    { label: "South West Africa", value: "South West Africa Time" },
    { label: "Atlantic Standard", value: "Atlantic Standard Time" },
    { label: "Armenia Standard", value: "Armenia Standard Time" },
    { label: "Moscow Standard", value: "Moscow Standard Time" },
    { label: "Falkland Islands Standard", value: "Falkland Islands Standard Time" },
    { label: "Saint Pierre and Miquelon Standard", value: "Saint Pierre and Miquelon Standard Time" },
    { label: "Bolivia", value: "Bolivia Time" },
    { label: "Guyana", value: "Guyana Time" },
    { label: "Ecuador", value: "Ecuador Time" },
    { label: "Paraguay", value: "Paraguay Time" },
    { label: "Suriname", value: "Suriname Time" },
    { label: "Uruguay", value: "Uruguay Time" },
    { label: "Brunei Darussalam", value: "Brunei Darussalam Time" },
    { label: "Nauru", value: "Nauru Time" },
    { label: "Papua New Guinea", value: "Papua New Guinea Time" },
    { label: "Tonga", value: "Tonga Time" },
    { label: "Solomon Islands", value: "Solomon Islands Time" },
    { label: "Vanuatu", value: "Vanuatu Time" },
    { label: "Fiji", value: "Fiji Time" },
    { label: "Palau", value: "Palau Time" },
    { label: "Wallis and Futuna", value: "Wallis and Futuna Time" },
    { label: "Cook Islands", value: "Cook Islands Time" },
    { label: "Niue", value: "Niue Time" },
    { label: "Samoa Standard", value: "Samoa Standard Time" },
    { label: "Gilbert Islands", value: "Gilbert Islands Time" },
    { label: "New Caledonia", value: "New Caledonia Time" },
    { label: "Tuvalu", value: "Tuvalu Time" },
    { label: "Tahiti", value: "Tahiti Time" },
    { label: "Pitcairn Islands", value: "Pitcairn Islands Time" },
    { label: "Kosrae", value: "Kosrae Time" },
    { label: "Marshall Islands", value: "Marshall Islands Time" }
  ];

  const fetchData = () => {
    const controller = new AbortController();

    const getSettings = async () => {
      try {
        const response = await axiosPrivate.get('/api/User/settings/' + cookies.user, {
          signal: controller.signal
        });

        loadData(response.data);

      } catch (err) {
        console.log(err);
      }
    }

    getSettings();

    return () => {
      controller.abort();
    }
  }

  const loadData = (settings) => {
    setNotificationId(settings.notificationId);
    setName(settings.fullName);
    setCellPhone(`${settings.countryCode} ${settings.cellPhone}`);
    setEmail(settings.email);
    setTimeZone(settings.userTimeZone);
  }

  const handleSave = () => {

    setLoading(true);

    const notifSettings = {
      notificationId: notificationId,
      fullName: name,
      cellPhone: cellPhone.substring(cellPhone.indexOf(' ') + 1),
      email: email.toLowerCase(),
      countryCode: cellPhone.substring(0, cellPhone.indexOf(' ')),
      userTimeZone: timeZone
    };

    axiosPrivate.post("/api/NotificationSettings/savePersonalInfo", notifSettings)
      .then(() => {
        fetchData();
      })
      .catch((error) => {
        console.error(error);
        alert("Error saving settings. Please try again.");
      })
      .finally(() => {
        console.warn(notifSettings);
        setLoading(false);
      })
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line 
  }, [])

  const handlePhone = (newValue) => {
    if (matchIsValidTel(newValue, {
      onlyCountryies: [],
      excludedCountryies: [],
      continents: []
    })) {
    }
    setCellPhone(newValue);
  };

  useEffect(() => {
    setValidPwd(PWD_REGEX.test(pwd));
    setValidMatch(pwd === matchPwd);
  }, [pwd, matchPwd]);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleOldPwdValidate = (event) => {
      event.preventDefault();
      const user = {
          email: cookies.user,
          password: oldPwd,
      };

      axios.post("/api/User/validatePassword", user)
          .then((response) => {
              setValidOldPwd(true);
          })
          .catch((error) => {
              console.error(error);
              alert("Password Incorrect. Please try again.");
          });
  };

  const handleChange = (event) => {
      event.preventDefault();
      const user = {
          email: cookies.user,
          password: pwd,
      };

      axios.post("/api/User/changePassword", user)
          .then((response) => {
              alert("Password reset successfully!")
              handleCleanup();
          })
          .catch((error) => {
              console.error(error);
              alert("Could not change password. Please try again.");
          });
  }

  const handleCleanup = () => {
    setValidOldPwd(false);
    setOldPwd('');
    setPwd('');
    setMatchPwd('');

  }

  return (
    <>
      <Container style={{ paddingTop: "40px" }}>
        <Grid
          container
          justifyContent="center" // Center vertically
          alignItems="center" // Center horizontally
          style={{ height: "2%", paddingTop: "1%", marginBottom: "1%" }} // Center vertically
          >
          <Typography
            variant="h6"
            alignItems="center"
            fontFamily="monospace">
            Account
          </Typography>
        </Grid>
        <Paper elevation={3} sx={{ padding: 3, mb: 3 }}>
          <Typography variant="h6" gutterBottom>Personal Information</Typography>
          <TextField
            label="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            fullWidth
            sx={{ marginBottom: 2 }}
            />
          <MuiTelInput
            fullWidth
            label="Cell Phone"
            name="phone"
            value={cellPhone}
            defaultCountry='US'
            preferredCountries={['US', 'CA', 'FR']}
            onChange={handlePhone}
            sx={{ marginBottom: 2 }}
            />
          <TextField
            label="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
            sx={{ marginBottom: 2 }}
            />
          <FormControl fullWidth>
              <InputLabel>Select Time Zone</InputLabel>
              <Select
                value={timeZone}
                onChange={(e) => setTimeZone(e.target.value)}
                label="Select Time Zone"
              >
                {timeZones.map((tz) => (
                  <MenuItem key={tz.value} value={tz.value}>{tz.label}</MenuItem>
                ))}
              </Select>
          </FormControl>
          <Grid item xs={12} style={{ paddingTop: "10px", paddingBottom: "10px" }}>
            <Box display="flex" justifyContent="flex-end">
              <Button variant="contained" onClick={handleSave}>
                {loading
                  ? <CircularProgress />
                  : "Save"}
              </Button>
            </Box>
          </Grid>
        </Paper>
        <Paper elevation={3} sx={{ padding: 3, mb: 3 }}>
          <Typography variant="h6" gutterBottom>Change Password</Typography>
          {validOldPwd ?
            <>
              <Grid item xs={12} style={{ paddingTop: "10px", paddingBottom: "10px" }}>
                <TextField
                    required
                    fullWidth
                    id="password"
                    label="Password"
                    name="password"
                    type={showPassword ? 'text' : 'password'}
                    autoComplete="new-password"
                    error={!validPwd && pwdFocus}
                    helperText={!validPwd && pwdFocus ? 
                      "Password must be atleast 8 characters long and have atleast 1 capital letter, 1 lowercase letter, 1 number, and 1 special character" 
                      : ""}
                    onFocus={() => setPwdFocus(true)}
                    onBlur={() => setPwdFocus(false)}
                    onChange={(e) => setPwd(e.target.value)}
                    InputProps={
                        {
                            endAdornment: (
                                < InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }
                    }
                />
              </Grid>
              <Grid style={{ paddingTop: "10px", paddingBottom: "10px" }}>
                  <TextField
                      required
                      fullWidth
                      type="password"
                      id="confirmPassword"
                      label="Confirm Password"
                      name="confirmPassword"
                      autoComplete="confirmPassword"
                      error={!validMatch && matchFocus}
                      helperText={!validMatch && matchFocus ? "Passwords do not match" : ""}
                      onFocus={() => setMatchFocus(true)}
                      onBlur={() => setMatchFocus(false)}
                      onChange={(e) => setMatchPwd(e.target.value)}
                  />
              </Grid>
              <Grid item xs={12} style={{ paddingTop: "10px", paddingBottom: "10px" }}>
                <Box display="flex" justifyContent="flex-end">
                  <Button variant="contained" onClick={handleChange}>
                    {loading
                      ? <CircularProgress />
                      : "Change Password"}
                  </Button>
                </Box>
              </Grid>
            </>
            :
            <>
              <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Current Password"
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  onChange={(e) => setOldPwd(e.target.value)}
                  InputProps={
                      {
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                            >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        )
                      }
                  }
              />

              <Grid item xs={12} style={{ paddingTop: "10px", paddingBottom: "10px" }}>
                <Box display="flex" justifyContent="flex-end">
                  <Button variant="contained" onClick={handleOldPwdValidate}>
                    {loading
                      ? <CircularProgress />
                      : "Validate Password"}
                  </Button>
                </Box>
              </Grid>
            </>
          }
        </Paper>
      </Container>
    </>
  )
}