import { createContext, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import axios from "./Axios";

const AuthContext = createContext({});

export function AuthProvider({ children }) {
    const [cookies, setCookies, removeCookie] = useCookies();

    const navigate = useNavigate();

    const signin = async (username, password, setLoading) => {
        const newUser = {
            email: username,
            password: password,
        };
        axios.post("/api/User/login", newUser)
            .then((response) => {
                if (response.data.accessToken) {
                    setCookies('jwtToken', response.data.accessToken);
                    setCookies('refToken', response.data.refreshToken);
                    setCookies('name', response.data.name);
                    setCookies('manager', response.data.isManager);
                    setCookies('isInternalUser', response.data.isInternalUser);
                    setCookies('user', username);
                    navigate("/");
                }
            })
            .catch((error) => {
                console.error(error);
                setLoading(false);
                alert("Username or password is incorrect. Please try again.");
            })

    }

    const signout = () => {
        ['jwtToken', 'refToken', 'name', 'manager', 'isInternalUser', 'user'].forEach(obj => removeCookie(obj));
        navigate('/signin');
    }

    const refreshToken = (token) => {
        setCookies('jwtToken', token);
    }

    const value = useMemo(
        () => ({
            cookies,
            signin,
            signout,
            refreshToken
        }),
        // eslint-disable-next-line 
        [cookies]
    )

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    )
};

export function useAuth() {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};