import React, { useState, useEffect } from "react";
import {
    Box, Typography, Switch, Button, IconButton,
    FormControlLabel, FormGroup, FormLabel, Modal, Tooltip,
    Container, Paper, Grid, Dialog, DialogActions, DialogContent, DialogTitle,
    FormControl, Snackbar, CircularProgress, Stack, Radio, RadioGroup
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import EditIcon from '@mui/icons-material/Edit';

import { Info as InfoIcon } from '@mui/icons-material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import useAxiosPrivate from "../utils/useAxiosPrivate";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height: '80%',
    minWidth: '40%',
    overflow: 'scroll',
    overflowX: "hidden",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const widthStyle = { width: "fit-content" };

export default function EditSettings({ user, onUpdateUser, onClose }) {
    const [editedUser, setEditedUser] = useState(user);
    const [open, setOpen] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const [notificationId, setNotificationId] = useState(null);
    const [userId, setUserId] = useState(null);
    const [pressureOn, setPressureOn] = useState(false);
    const [sprayOn, setSprayOn] = useState(false);
    const [hotWorksAlerts, setHotWorks] = useState(false);
    const [waterOn, setWaterOn] = useState(false);
    const [eButtonOn, setEButtonOn] = useState(false);
    const [eCareOn, setECareOn] = useState(false);
    const [weekdayValue, setWeekdayValue] = useState('off');
    const [weekdaysOn, setWeekdaysOn] = useState(false);
    const [weekdayScheduleOn, setWeekdayScheduleOn] = useState(false);
    const [weekendValue, setWeekendValue] = useState('off');
    const [weekendsOn, setWeekendsOn] = useState(false);
    const [weekendScheduleOn, setWeekendScheduleOn] = useState(false);
    const [weekdaysStartTime, setWeekDaysStartTime] = useState(null);
    const [weekdaysEndTime, setWeekDaysEndTime] = useState(null);
    const [weekendsStartTime, setWeekEndsStartTime] = useState(null);
    const [weekendsEndTime, setWeekEndsEndTime] = useState(null);
    const [weekdayNotificationMode, setWeekdayNotificationMode] = useState("accumulation");
    const [weekendNotificationMode, setWeekendNotificationMode] = useState("accumulation");
    const [name, setName] = useState("");
    const [cellPhone, setCellPhone] = useState("");
    const [email, setEmail] = useState("");
    const [preferredLanguage, setPreferredLanguage] = useState("1");
    const [modalVisible, setModalVisible] = useState(false);
    const [infoModalVisible, setInfoModalVisible] = useState(false);
    const [smsOn, setSMSOn] = useState(false);
    const [pushNotificationsOn, setPushNotificationsOn] = useState(false);
    const [emailsOn, setEmailsOn] = useState(false);

    const axiosPrivate = useAxiosPrivate();

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleSnackbarClose = () => setSnackbarOpen(false);

    const handleSave = () => {
        //setLoading(true);
        setEditedUser(editedUser)

        const notifSettings = {
            notificationId: notificationId,
            userId: userId,
            pressureOn: pressureOn,
            sprayOn: sprayOn,
            hotWorksAlerts: hotWorksAlerts,
            waterOn: waterOn,
            eButton: eButtonOn,
            eCare: eCareOn,
            sms: smsOn,
            pushNotification: pushNotificationsOn,
            emailNotification: emailsOn,
            weekdayNotificationMode: weekdayNotificationMode,
            weekendNotificationMode: weekendNotificationMode,
            fullName: name,
            cellPhone: cellPhone.substring(cellPhone.indexOf(' ') + 1),
            email: email,
            countryCode: cellPhone.substring(0, cellPhone.indexOf(' ')),
            prefferedLanguage: preferredLanguage,
            weekdays: weekdaysOn,
            weekEnds: weekendsOn,
            weekdaySchedule: weekdayScheduleOn,
            weekEndSchedule: weekendScheduleOn,
            weekdayStartTime: weekdaysStartTime && dayjs(weekdaysStartTime).isValid() ? new Date(weekdaysStartTime).toLocaleTimeString('en-US', { hour12: false }) : null,
            weekdayEndTime: weekdaysEndTime && dayjs(weekdaysEndTime).isValid() ? new Date(weekdaysEndTime).toLocaleTimeString('en-US', { hour12: false }) : null,
            weekendStartTime: weekendsStartTime && dayjs(weekendsStartTime).isValid() ? new Date(weekendsStartTime).toLocaleTimeString('en-US', { hour12: false }) : null,
            weekendEndTime: weekendsEndTime && dayjs(weekendsEndTime).isValid() ? new Date(weekendsEndTime).toLocaleTimeString('en-US', { hour12: false }) : null
          };
      
          axiosPrivate.post("/api/NotificationSettings/saveNotificationSettings", notifSettings)
            .then(() => {
              handleClose();
              setSnackbarOpen(true);
            })
            .catch((error) => {
              console.error(error);
              alert("Error saving settings. Please try again.");
            })
            .finally(() => {
              setLoading(false);
            })
    };

    const loadData = (settings) => {
        setNotificationId(settings.notificationId);
        setUserId(settings.userId);
        setPressureOn(settings.pressureOn);
        setSprayOn(settings.sprayOn);
        setHotWorks(settings.hotWorksAlerts);
        setWaterOn(settings.waterOn);
        setEButtonOn(settings.eButtonOn);
        setECareOn(settings.eCare);
        setSMSOn(settings.sms);
        setPushNotificationsOn(settings.pushNotification);
        setEmailsOn(settings.emailNotification);
        setWeekdayNotificationMode(settings.weekdayNotificationMode ?? "accumulation");
        setWeekendNotificationMode(settings.weekendNotificationMode ?? "accumulation");
        setName(settings.fullName);
        setCellPhone(settings.countryCode + " " + settings.cellPhone);
        setEmail(settings.email);
        setPreferredLanguage(settings.prefferedLanguage);
        setWeekdayValue(handleLoadChange(settings.weekdays, settings.weekdaySchedule, settings.weekdayNotificationMode ?? "accumulation"));
        setWeekendValue(handleLoadChange(settings.weekends, settings.weekendSchedule, settings.weekendNotificationMode ?? "accumulation"));
        setWeekdaysOn(settings.weekdays);
        setWeekendsOn(settings.weekends);
        setWeekdayScheduleOn(settings.weekdaySchedule);
        setWeekendScheduleOn(settings.weekendSchedule);
        setWeekDaysStartTime(dayjs(`2023-01-01T${settings.weekdayStartTime}`));
        setWeekDaysEndTime(dayjs(`2023-01-01T${settings.weekdayEndTime}`));
        setWeekEndsStartTime(dayjs(`2023-01-01T${settings.weekendStartTime}`));
        setWeekEndsEndTime(dayjs(`2023-01-01T${settings.weekendEndTime}`));
    }

    useEffect(() => {
        loadData(user);
        // eslint-disable-next-line 
    }, []);

    const handleSms = (sms) => {
        if (sms && !smsOn) {
            alert('SMS Enabled: Message & Data rates may apply.');
        }
        setSMSOn(!smsOn);
    }

    const handlePreAction = () => {
        setPressureOn(!pressureOn);
        setWaterOn(!waterOn);
    };

    const handleWeekdays = (event) => {
        if (!event.target.checked) {
            setWeekdayScheduleOn(false);
        }

        setWeekdaysOn(!weekdaysOn);
    }

    const handleWeekends = (event) => {
        if (!event.target.checked) {
            setWeekendScheduleOn(false);
        }

        setWeekendsOn(!weekendsOn);
    }

    const notificationModes = [
        { label: 'Accumulation', value: 'accumulation' },
        { label: 'Start/Stop', value: 'startStop' }
    ];

    const handleWeekdayChange = (event) => {
        let selected = event.target.value;
        setWeekdayValue(selected);
        if (selected === 'on24/7') {
          setWeekdaysOn(true);
          setWeekdayScheduleOn(false);
        } else if (selected === 'off') {
          setWeekdaysOn(false);
          setWeekdayScheduleOn(false);
        } else if (selected === 'accumulation') {
          setWeekdaysOn(true);
          setWeekdayScheduleOn(true);
          setWeekdayNotificationMode('accumulation');
        } else if (selected === 'startStop') {
          setWeekdaysOn(true);
          setWeekdayScheduleOn(true);
          setWeekdayNotificationMode('startStop');
        }
      };
    
      const handleWeekendChange = (event) => {
        let selected = event.target.value;
        setWeekendValue(selected);
        if (selected === 'on24/7') {
          setWeekendsOn(true);
          setWeekendScheduleOn(false);
        } else if (selected === 'off') {
          setWeekendsOn(false);
          setWeekendScheduleOn(false);
        } else if (selected === 'accumulation') {
          setWeekendsOn(true);
          setWeekendScheduleOn(true);
          setWeekendNotificationMode('accumulation');
        } else if (selected === 'startStop') {
          setWeekendsOn(true);
          setWeekendScheduleOn(true);
          setWeekendNotificationMode('startStop');
        }
      };
    
      const handleLoadChange = (days, schedule, mode) => {
        if (days && !schedule) {
          return 'on24/7'
        } else if (days && schedule && mode === 'accumulation') {
          return 'accumulation'
        } else if (days && schedule && mode === 'startStop') {
          return 'startStop'
        } else {
          return 'off'
        }
      }

    return (
        <>
            <Tooltip title="Edit User Notification Settings"> 
                <IconButton aria-label="edit" onClick={handleOpen} color="primary">
                    <EditIcon />
                </IconButton>
            </Tooltip>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Button variant="outlined" onClick={handleClose} style={{ position: 'absolute', top: '10px', right: '10px' }} disabled={loading}>X</Button>

                    <Typography variant="h6" component="h2" style={{ paddingBottom: "20px" }}>
                        Edit User Settings
                    </Typography>

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Container>
                            <Paper elevation={3} sx={{ padding: 3, marginBottom: 3 }}>
                                <Typography variant="h6" paddingLeft={3} gutterBottom>Alerts I Want to Receive</Typography>
                                <FormGroup>
                                <FormControlLabel
                                    control={<Switch checked={pressureOn && waterOn} onChange={handlePreAction} />}
                                    label="Verified Fire - System Prepared for Engagement"
                                    style={widthStyle}
                                />
                                <FormControlLabel
                                    control={<Switch checked={sprayOn} onChange={() => setSprayOn(!sprayOn)} />}
                                    label="Suppression Activated"
                                    style={widthStyle}
                                />
                                <FormControlLabel
                                    control={<Switch checked={hotWorksAlerts} onChange={() => setHotWorks(!hotWorksAlerts)} />}
                                    label="Mobile Suppression Unit - Mode Changes"
                                    style={widthStyle}
                                />
                                </FormGroup>
                            </Paper>

                            <Paper elevation={3} sx={{ padding: 3, marginBottom: 3 }}>
                                <Typography variant="h6" paddingLeft={3} gutterBottom>Alert Delivery Methods</Typography>
                                <FormGroup>
                                <FormControlLabel
                                    control={<Switch checked={smsOn} onChange={handleSms} />}
                                    label="SMS"
                                    style={widthStyle}
                                />
                                <FormControlLabel
                                    control={<Switch checked={pushNotificationsOn} onChange={() => setPushNotificationsOn(!pushNotificationsOn)} />}
                                    label="Fire Rover Mobile App Notifications"
                                    style={widthStyle}
                                />
                                <FormControlLabel
                                    control={<Switch checked={emailsOn} onChange={() => setEmailsOn(!emailsOn)} />}
                                    label="Email Notifications"
                                    style={widthStyle}
                                />
                                </FormGroup>
                            </Paper>

                            <Paper elevation={3} sx={{ padding: 3, marginBottom: 3 }}>
                                <Stack direction="row">
                                <Typography variant="h6" paddingLeft={3} gutterBottom>When I Want to Recieve Alerts</Typography>
                                <IconButton onClick={() => setInfoModalVisible(true)} sx={{ padding: "0px 4px 0px 4px", marginBottom: "8px", marginLeft: "4px" }}>
                                    <InfoIcon />
                                </IconButton>
                                </Stack>

                                <Stack padding={2}>
                                <FormControl>
                                    <FormLabel id="weekdays-controlled-radio-buttons-group">Weekdays</FormLabel>
                                    <RadioGroup
                                    aria-labelledby="weekdays-controlled-radio-buttons-group"
                                    name="controlled-radio-buttons-group"
                                    value={weekdayValue}
                                    onChange={handleWeekdayChange}
                                    >
                                    <FormControlLabel value="on24/7" control={<Radio />} label="On 24 Hours" />
                                    <FormControlLabel value="off" control={<Radio />} label="Off" />
                                    <FormControlLabel value="accumulation" control={<Radio />} label="On per Schedule: Accumulation — Notifications received during off hours will be held and delivered in bulk at the start of the next operational period." />
                                    <FormControlLabel value="startStop" control={<Radio />} label="On per Schedule: Start/Stop — Notifications received during off hours will not be delivered but will be stored in archive." />
                                    </RadioGroup>
                                </FormControl>
                                </Stack>
                                <Grid container direction="column" spacing={1}>
                                {weekdaysOn && weekdayScheduleOn && (
                                    <>
                                    <Grid item container spacing={2} alignItems="center" sx={{ paddingTop: "10px", paddingBottom: "5px" }}>
                                        <Grid item>
                                        <Typography>Start Time:</Typography>
                                        </Grid>
                                        <Grid item>
                                        <TimePicker label="Start Time" value={weekdaysStartTime} onChange={setWeekDaysStartTime} />
                                        </Grid>
                                    </Grid>

                                    <Grid item container spacing={2} alignItems="center">
                                        <Grid item>
                                        <Typography>End Time:</Typography>
                                        </Grid>
                                        <Grid item style={{ paddingLeft: "21px", paddingBottom: "10px" }}>
                                        <TimePicker label="End Time" value={weekdaysEndTime} onChange={setWeekDaysEndTime} />
                                        </Grid>
                                    </Grid>
                                    </>
                                )}

                                <Stack paddingLeft={3}>  
                                    <FormControl>
                                    <FormLabel id="weekends-controlled-radio-buttons-group">Weekends</FormLabel>
                                    <RadioGroup
                                        aria-labelledby="weekends-controlled-radio-buttons-group"
                                        name="controlled-radio-buttons-group"
                                        value={weekendValue}
                                        onChange={handleWeekendChange}
                                        >
                                        <FormControlLabel value="on24/7" control={<Radio />} label="On 24 Hours" />
                                        <FormControlLabel value="off" control={<Radio />} label="Off" />
                                        <FormControlLabel value="accumulation" control={<Radio />} label="On per Schedule: Accumulation — Notifications received during off hours will be held and delivered in bulk at the start of the next operational period." />
                                        <FormControlLabel value="startStop" control={<Radio />} label="On per Schedule: Start/Stop — Notifications received during off hours will not be delivered but will be stored in archive." />
                                    </RadioGroup>
                                    </FormControl>
                                </Stack>
                                {weekendsOn && weekendScheduleOn &&(
                                    <>
                                    <Grid item container spacing={2} alignItems="center" sx={{ paddingTop: "10px", paddingBottom: "5px" }}>
                                        <Grid item>
                                        <Typography>Start Time:</Typography>
                                        </Grid>
                                        <Grid item>
                                        <TimePicker label="Start Time" value={weekendsStartTime} onChange={setWeekEndsStartTime} />
                                        </Grid>
                                    </Grid>

                                    <Grid item container spacing={2} alignItems="center">
                                        <Grid item>
                                        <Typography>End Time:</Typography>
                                        </Grid>
                                        <Grid item style={{ paddingLeft: "21px", paddingBottom: "10px" }}>
                                        <TimePicker label="End Time" value={weekendsEndTime} onChange={setWeekEndsEndTime} />
                                        </Grid>
                                    </Grid>
                                    </>
                                )}

                                <Dialog open={infoModalVisible} onClose={() => setInfoModalVisible(false)}>
                                    <DialogTitle>Time of Day Control Info</DialogTitle>
                                    <DialogContent>
                                    <Typography>
                                        Settings for weekdays and weekends are independent and can be the same or different for both.<br /><br />

                                        <strong>Off</strong>: You will not receive notifications at any time regardless of Alert and Notification Settings.<br /><br />
                                        <strong>On 24/7</strong>: You will receive notifications in real time 24/7.<br /><br />
                                        <strong>On Per Schedule: Accumulation</strong>: Notifications received during off hours will be held and delivered in bulk at the start of the next operational period.<br /><br />
                                        <strong>On Per Schedule: Start/Stop</strong>: Notifications received during off hours will not be delivered but will be stored in archive.
                                    </Typography>
                                    </DialogContent>
                                    <DialogActions>
                                    <Button onClick={() => setInfoModalVisible(false)}>Close</Button>
                                    </DialogActions>
                                </Dialog>

                                <Dialog open={modalVisible} onClose={() => setModalVisible(false)}>
                                    <DialogTitle>Notification Modes Info</DialogTitle>
                                    <DialogContent>
                                    <Typography>
                                        Available when Time of Day Controls is turned on. <br /><br />
                                        <strong>Accumulation</strong>: Notifications received during off hours will be held and delivered in bulk at the start of the next operational period. <br /><br />
                                        <strong>Start/Stop</strong>: Notifications received during off hours will not be delivered but will be stored in archive.
                                    </Typography>
                                    </DialogContent>
                                    <DialogActions>
                                    <Button onClick={() => setModalVisible(false)}>Close</Button>
                                    </DialogActions>
                                </Dialog>
                                </Grid>
                            </Paper>

                            <Grid item xs={12} style={{ paddingTop: "10px", paddingBottom: "10px" }}>
                                <Box display="flex" justifyContent="flex-end">
                                    <Button variant="contained" onClick={handleSave}>
                                        {loading
                                            ? <CircularProgress />
                                            : "Save"}
                                    </Button>
                                </Box>
                            </Grid>
                        </Container>
                    </LocalizationProvider>
                </Box>
            </Modal>
            <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={handleSnackbarClose}>
                <MuiAlert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
                    User notification settings updated!
                </MuiAlert>
            </Snackbar>
        </>
    );
}
